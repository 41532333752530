@import url("bootstrap/dist/css/bootstrap.css");
@import url("bootstrap-icons/font/bootstrap-icons.css");

.scenario.card {
    cursor: pointer;

    &:hover {
        background-color: #0d6efd;
    }
}

.big-mode .font-monospace {
    font-size: 18pt;
}

.big-mode .container {
    width: 100%;
    max-width: none;
    margin: 0;
}

@media print {
    .pending-story-text, .failed-story-text, .user-input.form {
        display: none;
    }
    .story-text *, .choice, .chosen-option {
        font-family: "Times New Roman", Times, serif !important;
        color: black !important;
    }
    .chosen-option::before {
        content: "> ";
    }
    .story-image {
        padding-top: 2rem;
        width: 70%;
    }
    .story-element {
        display: block;
    }
    .story-image-column {
        display: block;
        width: 100%;
        text-align: center;
    }
    .story-text-column {
        display: block;
        width: 100%;
    }
}